import $ from "jquery";
import {isNotBlank} from "../../../common-string.js";

/**
 * Update all the agent phone number links on the page.
 *
 * @param {string} agentPhoneNumberValue
 * @param {string} agentPhoneNumberUriValue
 */
export function updateAgentPhoneNumber(agentPhoneNumberValue, agentPhoneNumberUriValue) {
    if (isNotBlank(agentPhoneNumberValue) && isNotBlank(agentPhoneNumberUriValue)) {
        const agentPhoneNumberLinks = $(".agent-phone");
        agentPhoneNumberLinks.html(agentPhoneNumberValue);
        agentPhoneNumberLinks.attr("href", agentPhoneNumberUriValue);
        $(".agent-phone-uri").attr("href", agentPhoneNumberUriValue);
    }
}
